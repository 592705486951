import { createRoute, redirect } from "@tanstack/react-router";
import { z } from "zod";
import { Loading } from "../components/loading";
import { Message } from "../components/message";
import { fetcher } from "../lib/fetcher";
import { rootRoute } from "./__root";

export const paymentLinkRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: "/l/$linkId",
	loader: async ({ params }) => {
		const { paymentId } = await fetcher(
			`${import.meta.env.VITE_API_URL}/checkout/links/${params.linkId}`,
			z.object({
				paymentId: z.string(),
			}),
		);

		throw redirect({
			to: "/pay/$paymentId",
			params: {
				paymentId,
			},
		});
	},
	component: LinkPage,
	errorComponent: () => (
		<Message
			title="Something went wrong"
			content="The page you were looking for could not be found. Please check the URL or contact the merchant."
		/>
	),
});

function LinkPage() {
	return <Loading className="w-screen h-screen" />;
}
