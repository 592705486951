import { useLoaderData } from "@tanstack/react-router";
import { useClickAway } from "@uidotdev/usehooks";
import { useMemo } from "react";
import { ArrowIcon } from "../assets/arrow";
import { ChevronIcon } from "../assets/chevron";
import { InflowIcon } from "../assets/inflow";
import { cn } from "../lib/cn";
import { hexToRgb } from "../lib/hex-to-rgb";
import { numberFormatter } from "../lib/number";
import { usePopupStore } from "../store/popup";
import { PricingDetails } from "./pricing-details";
import { PricingDetailsPopup } from "./pricing-details-popup";

type Props = {
	from: "/pay/$paymentId" | "/connect/$paymentId";
};

export function MerchantInfo({ from }: Props) {
	const { payment } = useLoaderData({ from });
	const { pricingDetails, setPricingDetails } = usePopupStore();

	const ref = useClickAway<HTMLDivElement>(() => {
		setPricingDetails(false);
	});

	const cancelUrl = useMemo(() => {
		if (!payment.cancelUrl) {
			return null;
		}

		const url = new URL(payment.cancelUrl);
		const search = new URLSearchParams(url.search);

		search.set("inflow_payment_id", payment.id);

		return `${url.origin}${url.pathname}?${search.toString()}`;
	}, [payment.id, payment.cancelUrl]);

	async function cancel(e: React.MouseEvent<HTMLAnchorElement>) {
		e.preventDefault();

		if (!cancelUrl) {
			return;
		}

		await fetch(
			`${import.meta.env.VITE_API_URL}/checkout/cancel/${payment.id}`,
			{ method: "POST" },
		);

		window.location.replace(cancelUrl);
	}

	return (
		<div ref={ref} className="w-full h-52 shrink-0 md:shrink md:h-full">
			<PricingDetailsPopup from={from} />
			<div
				className="w-full md:w-1/2 h-52 md:h-screen fixed top-0 left-0 right-0 flex justify-center xl:justify-end items-center z-10"
				style={{
					backgroundColor: payment.sessionCustomization?.bgColor || "#000",
					color: payment.sessionCustomization?.fontColor || "#fff",
				}}
			>
				<div className="w-full md:h-[75vh] md:overflow-auto max-w-lg flex flex-col space-y-6 p-4 xl:mr-4 2xl:mr-16">
					<div className="relative flex items-center">
						{cancelUrl && (
							<a
								href={cancelUrl}
								onClick={cancel}
								className="h-8 w-8 -left-10 mr-2 flex justify-center items-center rounded-full cursor-pointer"
							>
								<ArrowIcon
									height={14}
									style={{
										fill: hexToRgb(
											payment.sessionCustomization?.fontColor || "#ffffff",
										),
									}}
								/>
							</a>
						)}
						<div className="flex items-center space-x-2">
							{payment.sessionCustomization?.logoUrl && (
								<img
									src={payment.sessionCustomization?.logoUrl}
									alt={
										payment.sessionCustomization?.merchantName ||
										payment.merchant
									}
									className="h-6"
								/>
							)}
							<h1>
								{payment.sessionCustomization?.merchantName || payment.merchant}
							</h1>
						</div>
					</div>
					<div className="w-full flex flex-col items-center md:items-start space-y-2 py-4">
						<span
							className="font-semibold"
							style={{
								color: hexToRgb(
									payment.sessionCustomization?.fontColor || "#ffffff",
									0.6,
								),
							}}
						>
							Pay{" "}
							{payment.sessionCustomization?.merchantName || payment.merchant}
						</span>
						<span
							className="text-3xl font-bold"
							style={{
								color: payment.sessionCustomization?.fontColor || "#fff",
							}}
						>
							{numberFormatter(payment.amount / 100, {
								style: "currency",
								currency: payment.currency,
							})}
						</span>
						<button
							type="button"
							className="flex items-center space-x-2 md:hidden"
							onClick={() => setPricingDetails(!pricingDetails)}
						>
							<span
								style={{
									color: hexToRgb(
										payment.sessionCustomization?.fontColor || "#ffffff",
										0.8,
									),
								}}
							>
								Details
							</span>
							<ChevronIcon
								height={14}
								className={cn(
									"transform transition-transform duration-500",
									pricingDetails && "rotate-180 mt-px",
								)}
								style={{
									fill: hexToRgb(
										payment.sessionCustomization?.fontColor || "#ffffff",
										0.8,
									),
								}}
							/>
						</button>
					</div>
					<PricingDetails from={from} />
					<div className="md:flex items-start hidden">
						<span
							className="text-xs font-semibold"
							style={{
								color: hexToRgb(
									payment.sessionCustomization?.fontColor || "#ffffff",
									0.6,
								),
							}}
						>
							Powered by
						</span>
						<InflowIcon
							className="w-14 h-3"
							style={{
								fill: hexToRgb(
									payment.sessionCustomization?.fontColor || "#ffffff",
									0.6,
								),
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
