import { useLoaderData } from "@tanstack/react-router";
import { hexToRgb } from "../lib/hex-to-rgb";
import { numberFormatter } from "../lib/number";

type Props = {
	from: "/pay/$paymentId" | "/connect/$paymentId";
};

export function PricingDetails({ from }: Props) {
	const { payment } = useLoaderData({ from });

	return (
		<div className="md:flex flex-col hidden">
			<div className="w-full flex flex-col items-center space-y-4 py-4 overflow-y-auto max-h-60">
				{payment.products.map((product) => (
					<div
						key={`${product.name}-${product.price}-${product.quantity}`}
						className="w-full flex justify-between items-start"
					>
						<div className="flex flex-col">
							<span>{product.name}</span>
							<span
								style={{
									color: hexToRgb(
										payment.sessionCustomization?.fontColor || "#ffffff",
										0.6,
									),
								}}
							>
								Quantity: {product.quantity}
							</span>
						</div>
						<div className="flex flex-col items-end">
							<span>
								{numberFormatter((product.price * product.quantity) / 100, {
									style: "currency",
									currency: payment.currency,
								})}
							</span>
							{product.quantity > 1 && (
								<span
									style={{
										color: hexToRgb(
											payment.sessionCustomization?.fontColor || "#ffffff",
											0.6,
										),
									}}
								>
									{numberFormatter(product.price / 100, {
										style: "currency",
										currency: payment.currency,
									})}
									/each
								</span>
							)}
						</div>
					</div>
				))}
			</div>
			<div
				className="border-b"
				style={{
					borderColor: hexToRgb(
						payment.sessionCustomization?.fontColor || "#ffffff",
						0.2,
					),
				}}
			/>
			<div className="w-full flex justify-between items-start py-4">
				<span>Subtotal</span>
				<span>
					{numberFormatter(payment.amount / 100, {
						style: "currency",
						currency: payment.currency,
					})}
				</span>
			</div>
			<div
				className="border-b"
				style={{
					borderColor: hexToRgb(
						payment.sessionCustomization?.fontColor || "#ffffff",
						0.2,
					),
				}}
			/>
			<div className="w-full flex justify-between items-start py-4">
				<span>Total</span>
				<span>
					{numberFormatter(payment.amount / 100, {
						style: "currency",
						currency: payment.currency,
					})}
				</span>
			</div>
		</div>
	);
}
