import { useLoaderData } from "@tanstack/react-router";
import { cn } from "../lib/cn";
import { hexToRgb } from "../lib/hex-to-rgb";
import { numberFormatter } from "../lib/number";
import { usePopupStore } from "../store/popup";

type Props = {
	from: "/pay/$paymentId" | "/connect/$paymentId";
};

export function PricingDetailsPopup({ from }: Props) {
	const { payment } = useLoaderData({ from });
	const { pricingDetails } = usePopupStore();

	return (
		<div
			className={cn(
				"w-screen flex flex-col fixed top-0 left-0 transform transition-all duration-500 ease-in-out z-10 bg-red-500 md:hidden",
				pricingDetails ? "translate-y-52" : "-translate-y-2/3",
			)}
			style={{
				backgroundColor: payment.sessionCustomization?.bgColor || "#000",
				color: payment.sessionCustomization?.fontColor || "#fff",
			}}
		>
			<div className="flex flex-col w-full p-4 max-w-xl mx-auto">
				<div className="w-full flex flex-col items-center space-y-4 py-4 overflow-y-auto max-h-64">
					{payment.products.map((product) => (
						<div
							key={product.name}
							className="w-full flex justify-between items-start"
						>
							<div className="flex flex-col">
								<span>{product.name}</span>
								<span
									style={{
										color: hexToRgb(
											payment.sessionCustomization?.fontColor || "#ffffff",
											0.6,
										),
									}}
								>
									Quantity: {product.quantity}
								</span>
							</div>
							<div className="flex flex-col items-end">
								<span>
									{numberFormatter((product.price * product.quantity) / 100, {
										style: "currency",
										currency: payment.currency,
									})}
								</span>
								{product.quantity > 1 && (
									<span
										style={{
											color: hexToRgb(
												payment.sessionCustomization?.fontColor || "#ffffff",
												0.6,
											),
										}}
									>
										{numberFormatter(product.price / 100, {
											style: "currency",
											currency: payment.currency,
										})}
										/each
									</span>
								)}
							</div>
						</div>
					))}
				</div>
				<div
					className="border-b"
					style={{
						borderColor: hexToRgb(
							payment.sessionCustomization?.fontColor || "#ffffff",
							0.2,
						),
					}}
				/>
				<div className="w-full flex justify-between items-start py-4">
					<span>Subtotal</span>
					<span>
						{numberFormatter(payment.amount / 100, {
							style: "currency",
							currency: payment.currency,
						})}
					</span>
				</div>
				<div
					className="border-b"
					style={{
						borderColor: hexToRgb(
							payment.sessionCustomization?.fontColor || "#ffffff",
							0.2,
						),
					}}
				/>
				<div className="w-full flex justify-between items-start py-4">
					<span>Total</span>
					<span>
						{numberFormatter(payment.amount / 100, {
							style: "currency",
							currency: payment.currency,
						})}
					</span>
				</div>
			</div>
		</div>
	);
}
