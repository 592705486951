import { z } from "zod";

export const paymentStatusSchema = z.enum([
	"INITIATION",
	"CHECKOUT_PENDING",
	"CHECKOUT_SUCCESS",
	"CHECKOUT_CANCELED",
	"PAYMENT_RECEIVED",
	"PAYMENT_SUCCESS",
	"PAYMENT_FAILED",
]);

export type PaymentStatus = z.infer<typeof paymentStatusSchema>;

export const paymentSchema = z.object({
	id: z.string(),
	amount: z.number(),
	merchant: z.string(),
	currency: z.string(),
	customerEmail: z.string().nullable(),
	products: z.array(
		z.object({
			name: z.string(),
			price: z.number(),
			quantity: z.number(),
		}),
	),
	sessionCustomization: z
		.object({
			bgColor: z.string().nullable(),
			fontColor: z.string().nullable(),
			logoUrl: z.string().nullable(),
			merchantName: z.string().nullable(),
		})
		.optional(),
	successUrl: z.string().url(),
	cancelUrl: z.string().url().nullable(),
	status: paymentStatusSchema,
	createdAt: z.coerce.date(),
});
