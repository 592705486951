import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Outlet, createRootRoute } from "@tanstack/react-router";
import { ErrorMessage } from "../components/error-message";
import { Loading } from "../components/loading";

export const queryClient = new QueryClient();

export const rootRoute = createRootRoute({
	component: RootLayout,
	errorComponent: () => <ErrorMessage />,
	notFoundComponent: () => {
		window.location.replace("https://inflowpay.xyz");

		return <Loading className="w-screen h-screen" />;
	},
	pendingComponent: () => <Loading className="w-screen h-screen" />,
});

function RootLayout() {
	return (
		<QueryClientProvider client={queryClient}>
			<div className="text-sm md:text-base">
				<Outlet />
			</div>
		</QueryClientProvider>
	);
}
