import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery } from "@tanstack/react-query";
import { useLoaderData, useNavigate, useParams } from "@tanstack/react-router";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { bankLocales } from "../data/supported-locales";
import { cn } from "../lib/cn";
import { fetcher } from "../lib/fetcher";
import { isoToFlag } from "../lib/iso-to-flag";
import { bankSchema } from "../schemas/bank";
import { useCustomer } from "../store/customer";
import { Button } from "./ui/button";
import { Dropdown } from "./ui/dropdown";

const formValuesSchema = z.object({
	email: z.string().email().min(1),
	bankId: z.string().min(1),
});

type FormValues = z.infer<typeof formValuesSchema>;

export function BankInfo() {
	const navigate = useNavigate({ from: "/pay/$paymentId" });
	const { paymentId } = useParams({ from: "/pay/$paymentId" });
	const { payment } = useLoaderData({ from: "/pay/$paymentId" });
	const [search, setSearch] = useState("");
	const [locale, setLocale] = useState<undefined | string>();
	const { email, setCustomerEmail } = useCustomer();
	const { data: banks, isLoading } = useQuery({
		queryKey: ["banks", locale],
		queryFn: () =>
			fetcher(
				`${import.meta.env.VITE_API_URL}/checkout/banks/${paymentId}${
					locale ? `?locale=${locale}` : ""
				}`,
				z.object({
					banks: bankSchema.array(),
					locale: z.string(),
				}),
			),
	});
	const {
		handleSubmit,
		register,
		watch,
		formState: { errors },
	} = useForm<FormValues>({
		resolver: zodResolver(formValuesSchema),
		mode: "onSubmit",
		reValidateMode: "onChange",
		defaultValues: {
			email: email ?? "",
			bankId: undefined,
		},
	});
	const [expanded, setExpanded] = useState(false);

	const selectedBankId = watch("bankId");

	const filteredBanks = useMemo(() => {
		if (!banks) return [];

		return banks.banks
			.filter((bank) => bank.name.toLowerCase().includes(search.toLowerCase()))
			.slice(0, expanded ? banks.banks.length : 6);
	}, [banks, search, expanded]);

	function onSubmit(values: FormValues) {
		setCustomerEmail(values.email);

		navigate({
			to: "/connect/$paymentId",
			params: {
				paymentId,
			},
			search: {
				bank_id: values.bankId,
			},
		});
	}

	return (
		<div className="w-full h-[calc(100vh_-_13rem)] md:h-full flex justify-center xl:justify-start items-center">
			<div className="w-full h-full md:h-[75vh] max-w-lg flex flex-col space-y-6 p-4 xl:ml-4 2xl:ml-16">
				<div className="flex items-center justify-between">
					<h2 className="text-lg font-medium text-neutral-700">
						Pay with your bank
					</h2>
					{isLoading ? (
						<div className="h-[30px] w-24 rounded-md bg-neutral-100 animate-pulse" />
					) : (
						<Dropdown
							options={bankLocales}
							defaultSelected={
								bankLocales.find(({ value }) => value === banks?.locale)?.value
							}
							onChange={({ value }) => setLocale(value)}
							render={({ label, value }) => (
								<div className="px-3 py-1 text-left text-sm text-neutral-800 flex space-x-2 items-center">
									<span className="leading-3 mt-1">{isoToFlag(value)}</span>
									<span>{label}</span>
								</div>
							)}
						/>
					)}
				</div>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="flex flex-col space-y-6"
				>
					<div className="flex flex-col space-y-4">
						<fieldset className="flex flex-col space-y-1">
							<div className="flex items-center justify-between">
								<label htmlFor="email" className="text-sm text-neutral-600">
									Email
								</label>
								{errors.email && (
									<span className="text-sm text-red-500">
										{errors.email.message}
									</span>
								)}
							</div>
							<input
								id="email"
								type="text"
								disabled={!!payment.customerEmail}
								placeholder="Billing email address"
								className="w-full px-3 py-1.5 text-sm border border-neutral-300 hover:border-neutral-400 focus:border-neutral-400 outline-none rounded-md transition-colors duration-300 ease-in-out"
								{...register("email")}
							/>
						</fieldset>
						<fieldset className="flex flex-col space-y-1">
							<div className="flex items-center justify-between">
								<label
									htmlFor="bankSearch"
									className="text-sm text-neutral-600"
								>
									Search for your bank
								</label>
								{errors.bankId && (
									<span className="text-sm text-red-500">
										You must select a bank
									</span>
								)}
							</div>
							<input
								id="bankSearch"
								type="text"
								placeholder="Search for your bank"
								className="w-full px-3 py-1.5 text-sm border border-neutral-300 hover:border-neutral-400 focus:border-neutral-400 outline-none rounded-md transition-colors duration-300 ease-in-out"
								value={search}
								onChange={(e) => setSearch(e.target.value)}
							/>
						</fieldset>
						<fieldset className="grid grid-cols-2 lg:grid-cols-3 gap-4 overflow-auto">
							{filteredBanks.map((bank, i) => (
								<div
									key={`${bank.id}-${i}`}
									className={cn(
										"border rounded-md hover:border-neutral-400 transition-colors duration-300 ease-in-out",
										selectedBankId === bank.id
											? "border-neutral-500 bg-neutral-100 font-semibold"
											: "border-neutral-300",
									)}
								>
									<label htmlFor={`bank-id-${bank.id}`}>
										<input
											id={`bank-id-${bank.id}`}
											type="radio"
											className="hidden"
											value={bank.id}
											{...register("bankId")}
										/>
										<div className="h-full p-4 flex flex-col items-center space-y-2 cursor-pointer">
											<img
												src={bank.logo}
												alt={`${bank.name} logo`}
												className="max-w-full h-20 aspect-auto"
											/>
											<span className="text-sm text-center text-wrap line-clamp-2 truncate text-neutral-700">
												{bank.name}
											</span>
										</div>
									</label>
								</div>
							))}
							{filteredBanks.length === 0 && !isLoading && (
								<div className="col-span-2 lg:col-span-3 flex justify-center items-center p-4 border border-neutral-300 rounded-md">
									<span className="text-neutral-500">No banks found</span>
								</div>
							)}
							{banks && banks.banks.length > 6 && (
								<div className="col-span-2 lg:col-span-3 flex justify-center items-center">
									<button
										type="button"
										className="text-sm text-neutral-500 underline"
										onClick={() => setExpanded((prev) => !prev)}
									>
										{expanded ? "Show less" : "Show more"}
									</button>
								</div>
							)}
							{isLoading &&
								[...Array(6)].fill(null).map((_, i) => (
									<div
										// biome-ignore lint/suspicious/noArrayIndexKey: what else
										key={i}
										className="flex flex-col items-center space-y-2 p-4 border border-neutral-300 rounded-md animate-pulse"
									>
										<div className="w-20 h-20 bg-neutral-100 rounded-full" />
										<div className="w-20 h-4 bg-neutral-100 rounded-md" />
									</div>
								))}
							<div className="h-0" />
						</fieldset>
					</div>
					<Button intent="solid" size="big" type="submit">
						Pay by bank
					</Button>
					<div className="h-12" />
				</form>
			</div>
		</div>
	);
}
