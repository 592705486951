import { useQuery } from "@tanstack/react-query";
import {
	createRoute,
	useNavigate,
	useParams,
	useSearch,
} from "@tanstack/react-router";
import { z } from "zod";
import { Loading } from "../components/loading";
import { Message } from "../components/message";
import { fetcher } from "../lib/fetcher";
import { paymentSchema } from "../schemas/payment";
import { rootRoute } from "./__root";

const loadingSearchParamsSchema = z.object({
	ok: z.boolean().catch(true),
});

export const loadingRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: "/loading/$paymentId",
	component: LoadingPage,
	errorComponent: () => (
		<Message
			title="Something went wrong"
			content="The page you were looking for could not be found. Please check the URL or contact the merchant."
		/>
	),
	validateSearch: loadingSearchParamsSchema,
});

function LoadingPage() {
	const params = useParams({
		from: "/loading/$paymentId",
	});
	const { ok } = useSearch({
		from: "/loading/$paymentId",
	});
	const navigate = useNavigate({
		from: "/loading/$paymentId",
	});
	const { data } = useQuery({
		queryKey: ["payment", params.paymentId],
		queryFn: () => {
			return fetcher(
				`${import.meta.env.VITE_API_URL}/checkout/payment/${
					params.paymentId
				}?includeOriginalDevice=true`,
				paymentSchema.extend({
					fromOriginalDevice: z.boolean(),
				}),
			);
		},
		refetchInterval: 500,
	});

	if (!data) {
		return <Loading className="w-screen h-screen" />;
	}

	// If the payment is from the original device, but there was an error, redirect back to the payment page
	if (data?.fromOriginalDevice && !ok) {
		navigate({
			from: "/loading/$paymentId",
			to: "/pay/$paymentId",
			params: (current) => current,
		});
	}

	// If the payment is from the original device and the status is CHECKOUT_SUCCESS, redirect to the Merchant's successUrl
	if (data?.fromOriginalDevice && data?.status === "CHECKOUT_SUCCESS") {
		const url = new URL(data.successUrl);
		const search = new URLSearchParams(url.search);

		search.set("inflow_payment_id", params.paymentId);

		window.location.replace(
			`${url.origin}${url.pathname}?${search.toString()}`,
		);
	}

	// If the payment is not from the original device and there was an error, show an error message
	if (!data?.fromOriginalDevice && !ok) {
		return (
			<Message
				title="An error occured"
				content="Something went wrong while processing your payment. Please try again. If the problem persists, please contact the merchant."
			/>
		);
	}

	// If the payment is not from the original device but there was no error, show a generic message
	if (!data?.fromOriginalDevice && ok) {
		return (
			<Message
				title="Processing payment"
				content="Your payment is being processed. You can safely close this window and return to the payment page."
			/>
		);
	}

	return <Loading className="w-screen h-screen" />;
}
